const devEnvironments = ['localhost', 'tenable-dev--dassana-dev']
const deprecatedCloudEnvironments = ['tenable--dassana-dev']

const getBaseUrl = () => {
	const { host } = window.location

	if (devEnvironments.some(env => host.includes(env))) {
		return 'dassana.dev'
	}

	if (deprecatedCloudEnvironments.some(env => host.includes(env))) {
		return 'dassana.cloud'
	}

	return host.replace('console.tenable.', '')
}

export const BASE_URL = `https://auth.${getBaseUrl()}`

// Endpoints
export const JWT_ENDPOINT = '/token'
export const SERVICES_ENDPOINT = '/services'

// Interfaces
export * from './auth'
export * from './serviceMap'
