import { createUseStyles } from 'react-jss'
import PageLoader from 'assets/loader.gif'
import { styleguide } from '@dassana-io/web-components'
import React, { FC } from 'react'

const { flexCenter, flexDown, spacing } = styleguide

const useStyles = createUseStyles({
	loader: {
		...flexCenter,
		...flexDown,
		justifyContent: 'center'
	},
	text: {
		color: '#041E42',
		fontSize: 12,
		paddingTop: spacing.m
	}
})

export const Loader: FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.loader}>
			<img alt='loader' height={25} src={PageLoader} width={25} />
			<span className={classes.text}>LOADING</span>
		</div>
	)
}
