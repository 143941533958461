import history from 'localHistory'
import { select } from 'redux-saga/effects'
import { buildBrowserUrl, Services } from '@dassana-io/web-utils'

export function* pageRedirectSaga() {
	const storedUrl: string = yield select(state => state.auth.redirectUrl)

	const defaultLightVersionUrl = buildBrowserUrl({
		pathname: Services.appStore
	})

	history.push(storedUrl ? storedUrl : defaultLightVersionUrl)
}
