import { Action, ActionTypes } from 'actions'

export interface AuthState {
	loggedIn: boolean
	redirectUrl: string
}

export const initialState: AuthState = {
	loggedIn: false,
	redirectUrl: ''
}

const AuthReducer = (state = initialState, action: Action): AuthState => {
	switch (action.type) {
		case ActionTypes.login: {
			return { ...state, loggedIn: true }
		}

		case ActionTypes.logout: {
			return { ...state, loggedIn: false }
		}

		case ActionTypes.setRedirectUrl: {
			return { ...state, redirectUrl: action.redirectUrl }
		}

		default: {
			return state
		}
	}
}

export default AuthReducer
