import DassanaRouter from 'components/Router'
import history from 'localHistory'
import { initializeListeners } from 'utils/events'
import PageContainer from 'components/PageContainer'
import { Router } from 'react-router-dom'
import { useAppContext } from 'context/AppContext'
import { useNotification } from '@dassana-io/web-components'
import React, { FC, useEffect } from 'react'

const App: FC = () => {
	const { emitter } = useAppContext()
	const { generateNotification } = useNotification()

	useEffect(() => {
		// https://stackoverflow.com/a/76163164
		window.addEventListener('error', e => {
			if (e.message === 'ResizeObserver loop limit exceeded') {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				)
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				)

				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none')
				}

				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none')
				}
			}
		})
	}, [])

	useEffect(() => {
		initializeListeners(emitter, generateNotification)
	}, [emitter, generateNotification])

	return (
		<Router history={history}>
			<PageContainer>
				<DassanaRouter />
			</PageContainer>
		</Router>
	)
}

export default App
