import { AppContextProvider } from 'context/AppContext'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { createUseStyles } from 'react-jss'
import { Emitter } from '@dassana-io/web-utils'
import { NotificationProvider } from '@dassana-io/web-components'
import { Provider } from 'react-redux'
import React from 'react'
import reducers from 'reducers'
import reduxThunk from 'redux-thunk'
import rootSaga from 'sagas/rootSaga'
import { applyMiddleware, createStore } from 'redux'

const useStyles = createUseStyles({
	closeBtn: {
		'& > svg': {
			fontSize: 16
		},
		'& > svg > path': {
			fill: '#33363F'
		},
		'&:active:hover': {
			backgroundColor: 'rgba(22, 24, 28, .14)'
		},
		'&:hover': {
			backgroundColor: 'rgba(22, 24, 28, .08)'
		},
		borderRadius: '100px',
		cursor: 'pointer',
		height: 16,
		width: 16
	},
	notification: {
		'& > svg > path': {
			fill: '#C2212E'
		},
		backgroundColor: '#FFFFFF',
		borderRadius: 6,
		boxShadow: '0px 1px 30px -2px rgba(0,0,0,0.625)',
		color: '#33363F',
		fontFamily: 'Helvetica',
		fontSize: 14
	}
})

export const dassanaEmitter = new Emitter()

interface Props {
	children: JSX.Element
	initialState?: Object
}

const Root = ({ children, initialState = {} }: Props) => {
	const sagaMiddleware = createSagaMiddleware({
		context: { emitter: dassanaEmitter }
	})

	const middleware = [reduxThunk, sagaMiddleware]

	const store = createStore(
		reducers,
		initialState,
		composeWithDevTools(applyMiddleware(...middleware))
	)

	sagaMiddleware.run(rootSaga)

	const classes = useStyles()

	return (
		<Provider store={store}>
			<AppContextProvider value={{ emitter: dassanaEmitter }}>
				<NotificationProvider
					closeBtnClasses={[classes.closeBtn]}
					notificationClasses={[classes.notification]}
				>
					{children}
				</NotificationProvider>
			</AppContextProvider>
		</Provider>
	)
}

export default Root
