import Landing from 'pages/Landing'
import MicroFrontend from 'components/MicroFrontend'
import React from 'react'
import { Service } from 'api'
import { Services } from '@dassana-io/web-utils'
import { StoreState } from 'reducers'
import { useAppContext } from 'context/AppContext'
import { useSelector } from 'react-redux'
import { Error404, Plausible } from '@dassana-io/web-components'
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom'

const capitalize = (s: string): string => {
	return s.charAt(0).toUpperCase() + s.slice(1)
}

const Router = () => {
	const { emitter } = useAppContext()

	const serviceMap = useSelector(
		(state: StoreState): Service[] => state.serviceMap.map
	)
	const isLoggedIn = useSelector(
		(state: StoreState): boolean => state.auth.loggedIn
	)

	const redirect = `/${Services.appStore}`

	return (
		<Switch>
			<Route exact path='/'>
				{isLoggedIn ? <Redirect to={redirect} /> : <Landing />}
			</Route>
			{/* Exact from react-router should NOT be added to the following routes or MicroFrontends will break */}
			{/* The / in the path prop is REQUIRED. Please do not delete */}
			{serviceMap.map(({ apiUrl, serviceName, webUrl }) => (
				<Route
					key={serviceName}
					path={`/${serviceName}`}
					render={(routeProps: RouteChildrenProps): JSX.Element => (
						<MicroFrontend
							apiUrl={apiUrl}
							dassanaEmitter={emitter}
							history={routeProps.history}
							host={webUrl}
							key={serviceName}
							name={capitalize(serviceName)}
							serviceMap={serviceMap}
							showLoader
						/>
					)}
				/>
			))}
			<Route component={Plausible} path='/jummie' />
			<Route
				component={() =>
					isLoggedIn ? <Error404 showBtn={false} /> : <Landing />
				}
				path='*'
			/>
		</Switch>
	)
}

export default Router
