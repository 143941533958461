import { convertEpochToEpochMs } from 'utils/saga'
import history from 'localHistory'
import { parseParamsString } from '@dassana-io/web-utils'
import { TOKEN } from 'sagas/constants'
import { AdditionalJwtOptions, login, setRedirectUrl } from 'actions'
import { call, put } from 'redux-saga/effects'
import jwtDecode, { JwtPayload } from 'jwt-decode'

export function* detectExistingTokenSaga() {
	const { search = '' } = window.location

	const params = parseParamsString(search)

	const queryToken = params[TOKEN] as string

	if (queryToken) {
		localStorage.setItem(TOKEN, queryToken)
	}

	const token = localStorage.getItem(TOKEN)

	if (token) {
		try {
			const decoded = jwtDecode<JwtPayload & AdditionalJwtOptions>(token)

			const { exp } = decoded

			if (convertEpochToEpochMs(exp!) > Date.now()) {
				yield put(login())
			}
		} catch (error: any) {
			localStorage.removeItem(TOKEN)
		}
	}

	return !!token
}

export function* saveRedirectUrlSaga(hasToken: boolean) {
	const { hash = '', pathname, search = '' } = window.location

	const params = new URLSearchParams(search)

	params.delete(TOKEN)

	const stringifiedParms = params.toString()

	const processedSearch = stringifiedParms ? `?${stringifiedParms}` : ''

	const url = `${pathname}${processedSearch}${hash}`

	if (pathname !== '/') {
		yield put(setRedirectUrl(url))

		if (!hasToken) {
			history.push('/')
		}
	}
}

export default function* setupSessionSaga() {
	const hasToken: boolean = yield call(detectExistingTokenSaga)

	yield call(saveRedirectUrlSaga, hasToken)
}
