import { api } from '@dassana-io/web-utils'
import { pageRedirectSaga } from './pageRedirectSaga'
import { all, call, put } from 'redux-saga/effects'
import { BASE_URL, Service, SERVICES_ENDPOINT } from 'api'
import { logout, setServiceMap, setServiceMapLoading } from 'actions'

export async function fetchServiceMap(): Promise<Service[]> {
	const { data } = await api().get(SERVICES_ENDPOINT, { baseURL: BASE_URL })

	return data
}

export default function* fetchServiceMapAndRedirectSaga() {
	try {
		// [not blocking]. schedule dispatching of action
		yield put(setServiceMapLoading(true))

		// [blocking]. call fetchServiceMap async fn and set the returned value to serviceMap
		const serviceMap: Service[] = yield call(fetchServiceMap)

		// then schedule dispatching of action setServiceMap
		yield put(setServiceMap(serviceMap))

		yield call(pageRedirectSaga)

		yield put(setServiceMapLoading())
	} catch (error: any) {
		yield all([put(setServiceMapLoading()), put(logout())])
	}
}
