import {
	LoginAction,
	LogoutAction,
	SetRedirectUrlAction,
	SetServiceMapAction,
	SetServiceMapLoadingAction
} from 'actions'

export enum ActionTypes {
	login = 'login',
	logout = 'logout',
	setRedirectUrl = 'setRedirectUrl',
	setServiceMap = 'setServiceMap',
	setServiceMapLoading = 'setServiceMapLoading'
}

export type Action =
	| LoginAction
	| LogoutAction
	| SetRedirectUrlAction
	| SetServiceMapAction
	| SetServiceMapLoadingAction

export interface AdditionalJwtOptions {
	sourcesOnlyVersion: boolean
}
