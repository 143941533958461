import { createUseStyles } from 'react-jss'
import { Dispatch } from 'redux'
import { getRefreshToken } from 'sagas/refreshTokenSaga'
import { Loader } from 'components/Loader'
import { LoginAction } from 'actions'
import { TOKEN } from 'sagas/constants'
import { useAppContext } from 'context/AppContext'
import { useDispatch } from 'react-redux'
import { Error404, PageLoader, styleguide } from '@dassana-io/web-components'
import { handleAjaxErrors, useQueryParams } from '@dassana-io/web-utils'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import React, { useEffect, useState } from 'react'

const {
	colors: { whites },
	flexCenter,
	flexDown
} = styleguide

const useStyles = createUseStyles({
	container: {
		...flexCenter,
		...flexDown,
		color: whites.base,
		height: '100%',
		width: '100%'
	}
})

const LandingPage: React.FC = () => {
	const dispatch: Dispatch<LoginAction> = useDispatch()

	const { emitter } = useAppContext()

	const classes = useStyles()

	const { getParam } = useQueryParams()
	const token = getParam(TOKEN)

	const [isErrorState, setIsErrorState] = useState(false)

	useEffect(() => {
		const tokenLogin = async () => {
			if (token) {
				const tokenString = JSON.stringify(token)

				try {
					jwtDecode<JwtPayload>(tokenString)

					await getRefreshToken()
				} catch (error: any) {
					localStorage.removeItem(TOKEN)

					setIsErrorState(true)

					handleAjaxErrors(error, emitter)
				}
			}
		}

		tokenLogin()
	}, [dispatch, emitter, token])

	if (isErrorState) return <Error404 showBtn={false} />

	return <PageLoader classes={[classes.container]} loader={<Loader />} />
}

export default LandingPage
