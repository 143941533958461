import history from 'localHistory'
import { all, put } from 'redux-saga/effects'
import { LAST_ACTIVE, TOKEN } from 'sagas/constants'
import { setRedirectUrl, setServiceMap } from 'actions'

export default function* cleanupSessionSaga() {
	history.push('/')

	yield all([put(setRedirectUrl()), put(setServiceMap())])

	localStorage.removeItem(LAST_ACTIVE)
	localStorage.removeItem(TOKEN)
}
