import { ActionTypes } from 'actions'
import cleanupSessionSaga from './cleanupSessionSaga'
import detectTokenPresenceSaga from './detectTokenPresenceSaga'
import fetchServiceMapAndRedirectSaga from './fetchServiceMapAndRedirectSaga'
import refreshTokenSaga from './refreshTokenSaga'
import setupSessionSaga from './setupSessionSaga'
import { all, cancel, fork, take } from 'redux-saga/effects'

export default function* rootSaga(): Generator<any, any, any> {
	// [not blocking]. like call but not blocking. run the task and go to the next step.
	// setupSessionSaga checks if user is logged in and redirects to correct url
	yield fork(setupSessionSaga)

	while (true) {
		yield take(ActionTypes.login)

		const fetchServiceMapAndRedirectTask = yield fork(
			fetchServiceMapAndRedirectSaga
		)

		const [detectTokenPresenceTask, refreshTokenTask] = yield all([
			fork(detectTokenPresenceSaga),
			fork(refreshTokenSaga)
		])

		yield take(ActionTypes.logout)

		yield all([
			cancel(fetchServiceMapAndRedirectTask),
			fork(cleanupSessionSaga),
			cancel(detectTokenPresenceTask),

			cancel(refreshTokenTask)
		])
	}
}
