import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { Loader } from './Loader'
import { MICRO_FE_CONTAINER_ID } from '@dassana-io/web-utils'
import React from 'react'
import { StoreState } from 'reducers'
import { useSelector } from 'react-redux'
import {
	PageLoader,
	styleguide,
	themes,
	ThemeType
} from '@dassana-io/web-components'

const { flexDown } = styleguide
interface Props {
	children: JSX.Element
}

const useStyles = createUseStyles({
	container: {
		background: themes[ThemeType.light].background.primary,
		color: themes[ThemeType.light].text.primary,
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	contentContainer: {
		...flexDown,
		background: themes[ThemeType.light].background.primary,
		boxShadow: `inset 2px 0 10px -6px ${themes[ThemeType.light].border}`,
		flexGrow: 1,
		position: 'relative',
		width: '100vw'
	},
	hasContent: {
		'&:not(:empty)': {
			height: '100vh'
		}
	},
	microFeContainer: {
		display: 'flex'
	}
})

const PageContainer: React.FC<Props> = ({ children }: Props) => {
	const isAuthenticated = useSelector(
		(state: StoreState): boolean => state.auth.loggedIn
	)
	const isServiceMapLoading = useSelector(
		(state: StoreState): boolean => state.serviceMap.loading
	)

	const classes = useStyles()

	const microFeContainerClasses = cn({
		[classes.microFeContainer]: true,
		[classes.hasContent]: isAuthenticated
	})

	return (
		<div className={classes.container}>
			{isServiceMapLoading ? (
				<PageLoader loader={<Loader />} />
			) : (
				<>
					<div className={classes.contentContainer}>
						{children}
						<main
							className={microFeContainerClasses}
							id={MICRO_FE_CONTAINER_ID}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default PageContainer
