import { ActionTypes } from 'actions'

export interface LoginAction {
	type: ActionTypes.login
}

export function login(): LoginAction {
	return {
		type: ActionTypes.login
	}
}

export interface LogoutAction {
	type: ActionTypes.logout
}

export function logout(): LogoutAction {
	return {
		type: ActionTypes.logout
	}
}

export interface SetRedirectUrlAction {
	redirectUrl: string
	type: ActionTypes.setRedirectUrl
}

export function setRedirectUrl(redirectUrl = ''): SetRedirectUrlAction {
	return {
		redirectUrl,
		type: ActionTypes.setRedirectUrl
	}
}
